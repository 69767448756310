import * as React from 'react';

function Logo(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 66 76" fill="none"
            {...props}
        >
            <path d="M24.1221 68.6641C24.1221 66.9346 25.0312 65.9912 26.7607 65.9912C27.1709 65.9912 27.6631 66.0732 27.998 66.2031V64.4736C27.7383 64.3711 27.3486 64.3164 26.9248 64.3164C25.5029 64.3164 24.6689 64.9863 24.3613 66.4287H24.1289V64.5146H20.9912V65.7041H22.5908V70.8105H21.1348V72H26.3506V70.8105H24.1221V68.6641ZM36.7686 72V70.6123H34.1436V62.1357H32.5303L29.7549 63.9678V65.5742L32.4072 63.8311H32.5234V70.6123H29.7686V72H36.7686ZM40.4326 67.5703H41.5879C42.8115 67.5703 43.5977 68.2061 43.5977 69.1973C43.5977 70.168 42.832 70.7832 41.6221 70.7832C40.4873 70.7832 39.7148 70.1885 39.6328 69.2588H38.0947C38.1836 71.043 39.5918 72.1982 41.6631 72.1982C43.7754 72.1982 45.252 71.002 45.252 69.2861C45.252 67.96 44.459 67.0713 43.1328 66.9072V66.791C44.2061 66.5518 44.8555 65.6973 44.8555 64.5283C44.8555 62.9971 43.5361 61.9375 41.6289 61.9375C39.6465 61.9375 38.375 63.0381 38.2793 64.8291H39.79C39.8652 63.8789 40.542 63.291 41.5742 63.291C42.6064 63.291 43.2832 63.8652 43.2832 64.7676C43.2832 65.6768 42.5859 66.2988 41.5537 66.2988H40.4326V67.5703Z" fill="#64FFDA" />
            <path fillRule="evenodd" clipRule="evenodd" d="M33.3632 17.576L27.7064 11.9191C26.1443 10.357 23.6116 10.357 22.0495 11.9191L3.66473 30.3039C2.10263 31.866 2.10263 34.3987 3.66473 35.9608L22.0495 54.3455C23.6116 55.9076 26.1443 55.9076 27.7064 54.3455L46.0911 35.9608C47.6532 34.3987 47.6532 31.866 46.0911 30.3039L39.7272 23.9399L36.1916 27.4755L40.4343 31.7181C41.2153 32.4992 41.2153 33.7655 40.4343 34.5465L26.2921 48.6887C25.5111 49.4697 24.2448 49.4697 23.4637 48.6887L9.32158 34.5465C8.54053 33.7655 8.54053 32.4992 9.32158 31.7181L23.4637 17.576C24.2448 16.7949 25.5111 16.7949 26.2921 17.576L29.8277 21.1115L33.3632 17.576Z" fill="#64FFDA" />
            <path fillRule="evenodd" clipRule="evenodd" d="M43.7881 11.7569C42.226 10.1947 39.6934 10.1948 38.1313 11.7569L19.7465 30.1417C18.1844 31.7037 18.1844 34.2364 19.7465 35.7985L26.1105 42.1625L29.646 38.627L25.4034 34.3843C24.6224 33.6033 24.6223 32.337 25.4034 31.5559L39.5455 17.4137C40.3266 16.6327 41.5929 16.6328 42.3739 17.4137L56.5161 31.5559C57.2971 32.3369 57.2971 33.6032 56.5161 34.3843L42.3739 48.5264C41.5929 49.3075 40.3265 49.3074 39.5455 48.5264L36.5355 45.5164L32.9999 49.0519L38.1313 54.1833C39.6935 55.7455 42.2261 55.7454 43.7881 54.1833L62.1729 35.7985C63.735 34.2364 63.7351 31.7038 62.1729 30.1417L43.7881 11.7569Z" fill="#1FA859" />
        </svg>
    );
}

const MemoLogo = React.memo(Logo);

export default MemoLogo;
